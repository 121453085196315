import React, { Component } from 'react'
import { withTheme } from 'styled-components'
import translate from '../../../../../../modules/translate'
import PropTypes from 'prop-types'
import { getFont } from '../../../../../../modules/font'
import ErrorBoundary from '../../../../../Error/widgets/ErrorBoundary'
import MessageError from '../../../../../Error/screens/MessageError'
import DetailOnTour from '../../../../components/DetailOnTour'
import SkeletonDetailOnTour from '../../../../components/DetailOnTour/skeleton'
import pushEventToDataLayer from '../../../../../../modules/push-event-to-data-layer'

const errorOptions = {
  component: translate('ontour_detail'),
  link: '/',
  linkText: translate('error_try_homepage'),
  logError: 'OnTour Detail display',
}

class ScreenOnTourDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fontSize: getFont(),
    }
  }
  render() {
    const {
      loading,
      error,
      response: { data },
      routeState,
      ...props
    } = this.props

    if (data && data.districts && data.districts[0]) {
      pushEventToDataLayer('LocalArticle', ['DistrictName', data.districts[0].name])
    }

    return (
      <ErrorBoundary {...errorOptions} {...props}>
        {error && <MessageError logInfo={error.message} {...errorOptions} />}
        {loading && <SkeletonDetailOnTour fontSize={this.state.fontSize} routeState={routeState} />}
        {data && <DetailOnTour data={data} fontSize={this.state.fontSize} />}
      </ErrorBoundary>
    )
  }
}

ScreenOnTourDetail.propTypes = {
  response: PropTypes.shape({
    data: DetailOnTour.propTypes.data,
  }),
  routeState: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
}

ScreenOnTourDetail.defaultProps = {
  response: {
    data: undefined,
  },
}

export default withTheme(ScreenOnTourDetail)
