import React from 'react'
import styled from 'styled-components'
import NakedOnTourHeader from '../NakedOnTourHeader'
import { Wrapper0PoweredBy } from '../../components/PoweredBy' //PoweredBy
import Button from '../../../../../../components/Button'
import Icon from '../../../../../../components/Icon'
import translate from '../../../../../../modules/translate'
import isStolApp from '../../../../../../modules/is-stolapp'
import routes from '../../../../../routes'

const StyledNakedOnTourHeader = styled.div`
  padding: 10px 0 10px 0;
  align-items: center;
  ${Wrapper0PoweredBy} {
    align-items: center;
  }
  @media (max-width: 797px) {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    ${Wrapper0PoweredBy} {
      flex-direction: column;
      padding: 10px 0 10px 0;
    }
  }
`

const StyledButton = styled(Button)`
  padding: 5px 20px;
  font-size: ${(props) => props.theme.fontSize.small};
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.small};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.small};
  }
  color: white;
  border-color: white;
  background-image: url('${(props) => props.image};');
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`

const StyledIcon = styled(Icon)`
  font-size: 22px;
  padding-right: 5px;
`

const ViewGalleryOnTourHeader = ({ ...props }) => (
  <StyledNakedOnTourHeader as={NakedOnTourHeader} height="200px" {...props}>
    {
      //<PoweredBy flexDirection="column" />
    }
    {!isStolApp() && (
      <StyledButton to={routes.getOptions('ontour').path}>
        <StyledIcon icon="photo" /> {translate('ontour_view_galleries_button')}
      </StyledButton>
    )}
  </StyledNakedOnTourHeader>
)

export default ViewGalleryOnTourHeader
