import React, { Component } from 'react'
import Gallery from 'react-photo-gallery'

class GridGallery extends Component {
  shouldComponentUpdate() {
    return false
  }

  render() {
    const { photos, direction, targetRowHeight, columns, margin, renderImage } = this.props
    return (
      <Gallery
        photos={photos}
        direction={direction}
        margin={margin}
        targetRowHeight={targetRowHeight}
        columns={columns}
        renderImage={renderImage}
      />
    )
  }
}

export default GridGallery
