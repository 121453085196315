import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ImageWidescreen from '../../../../components/ImageWidescreen'
import DescriptionDetailOnTour from './components/DescriptionDetailOnTour'
import HeaderOnTour from '../HeaderOnTour'
import isStolApp from '../../../../modules/is-stolapp'
import checkForValueInUserAgent from '../../../../modules/check-for-value-in-useragent'
import Gallery from '../../../../components/Gallery'
import Block from '../../../../layouts/Blocks'

const GalleryWrapper = styled.div`
  margin-left: ${(props) => props.theme.default.sizes.gapEdge};
  margin-right: ${(props) => props.theme.default.sizes.gapEdge};
  margin-top: calc(${(props) => props.theme.default.sizes.gapVertical} / 2);
  margin-bottom: calc(${(props) => props.theme.default.sizes.gapVertical} / 2);

  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-left: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-right: ${(props) => props.theme.tablet.sizes.gapEdge};
    margin-top: calc(${(props) => props.theme.tablet.sizes.gapVertical} / 2);
    margin-bottom: calc(${(props) => props.theme.tablet.sizes.gapVertical} / 2);
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    margin-right: ${(props) => props.theme.mobile.sizes.gapEdge};
    margin-top: calc(${(props) => props.theme.mobile.sizes.gapVertical} / 2);
    margin-bottom: calc(${(props) => props.theme.mobile.sizes.gapVertical} / 2);
  }
`

const StyledImageWidescreen = styled(ImageWidescreen)`
  margin: 0 auto;
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
  width: calc(100% - (2 * ${(props) => props.theme.sizes.gapEdge}));
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.tablet.sizes.gapVertical};
    width: calc(100% - (2 * ${(props) => props.theme.tablet.sizes.gapEdge}));
    margin-top: calc(${(props) => props.theme.tablet.sizes.gapVertical} / 2);
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    margin-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
    width: calc(100% - (2 * ${(props) => props.theme.mobile.sizes.gapEdge}));
    margin-top: calc(${(props) => props.theme.mobile.sizes.gapVertical} / 2);
  }
`

const StyledBlock = styled(Block)`
  margin-bottom: ${(props) => props.theme.sizes.gapVertical};
`

const DetailOnTour = ({ data, fontSize, ...props }) => (
  <Fragment {...props}>
    <HeaderOnTour view="viewGalleryOnTour" />
    <DescriptionDetailOnTour
      title={data.title}
      content={data.content}
      date={data.date}
      headline={data.breadcrumb.headline}
      location={data.location}
      eventLocation={data.locationEvent}
      fontSize={fontSize}
    />
    {data.onTourImages && (
      <GalleryWrapper isStolApp={isStolApp()} isIphoneX={checkForValueInUserAgent('iphonex')}>
        <Gallery uid={data.id} type="ontour" photoMargin={5} images={data.onTourImages} />
      </GalleryWrapper>
    )}
    {data.image && data.onTourImages && data.onTourImages.length === 0 && <StyledImageWidescreen image={data.image} />}
    {data.otherOnTours && <StyledBlock view="ontour" data={data.otherOnTours} numberItems={6} noGapEdge={true} />}
  </Fragment>
)

DetailOnTour.propTypes = {
  data: {
    title: PropTypes.string,
    content: PropTypes.string,
    date: PropTypes.string,
    headline: PropTypes.string,
    location: PropTypes.string,
    eventLocation: PropTypes.string,
    onTourImages: PropTypes.array,
    image: PropTypes.object,
    otherOnTours: PropTypes.array,
  },
  fontSize: PropTypes.number,
}

DetailOnTour.defaultProps = {
  data: {},
}

export default DetailOnTour
