import React, { Fragment } from 'react'
import styled from 'styled-components'
import SkeletonWidgetNakedLineArticle, {ContainerSkeletonLineItemArticle0SkeletonWidgetNakedLineArticle} from '../WidgetNakedLineArticle/skeleton'

const StyledSkeletonWidgetNakedLineArticle = styled(SkeletonWidgetNakedLineArticle)`
    ${ContainerSkeletonLineItemArticle0SkeletonWidgetNakedLineArticle}{
        margin-top: ${props => props.theme.sizes.gapVertical};
    }
    @media(max-width: ${props => props.theme.tablet.breakPoint + 'px'}){
        ${ContainerSkeletonLineItemArticle0SkeletonWidgetNakedLineArticle}{
            margin-top: ${props => props.theme.tablet.sizes.gapVertical};
        }
    }
    @media(max-width: ${props => props.theme.mobile.breakPoint + 'px'}){
        ${ContainerSkeletonLineItemArticle0SkeletonWidgetNakedLineArticle}{
            margin-top: ${props => props.theme.mobile.sizes.gapVertical};
        }
    }
`

const SkeletonWidgetEvents = () => {
    return (
        <Fragment>
            <StyledSkeletonWidgetNakedLineArticle title containerArticle items={4} timeLine withMailIcon />
        </Fragment>
    )
}

export default SkeletonWidgetEvents
