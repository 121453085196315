import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import SkeletonNakedItemOnTour from '../../components/ItemOnTour/views/NakedItemOnTour/skeleton'

const Wrapper = styled.div`
  width: calc(100% - 2 * (${(props) => props.theme.sizes.gapEdge}));
  margin: ${(props) => (props.margin ? props.margin : '0')};
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding-right: ${(props) => props.theme.sizes.gapEdge};
  padding-left: ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: calc(100% - 2 * (${(props) => props.theme.tablet.sizes.gapEdge}));
    padding-right: ${(props) => props.theme.tablet.sizes.gapEdge};
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: calc(100% - 2 * (${(props) => props.theme.tablet.sizes.gapEdge}));
    padding-right: ${(props) => props.theme.mobile.sizes.gapEdge};
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
  @media (max-width: 450px) {
    padding-right: 0;
    padding-left: 0;
    width: 100%;
  }
`

const StyledSkeletonNakedItemOnTour = styled(SkeletonNakedItemOnTour)`
  flex: 1;
  height: auto;
  background-color: #ffff;
  flex-basis: calc((100% / 3) - 20px);
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: calc((100% / 2) - 20px);
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-basis: 100%;
  }
`

class SkeletonWidgetOnTourItems extends Component {
  render() {
    const { view, numberItems, margin, ...props } = this.props
    let SkeletonItems = []
    for (let i = 0; i < numberItems; i++) {
      SkeletonItems.push(
        <Fragment>
          <StyledSkeletonNakedItemOnTour />
        </Fragment>,
      )
    }
    return (
      <Wrapper margin={margin} {...props}>
        {SkeletonItems}
      </Wrapper>
    )
  }
}

SkeletonWidgetOnTourItems.propTypes = {
  margin: '0',
}

SkeletonWidgetOnTourItems.defaultProps = {
  numberItems: 1,
}

export default SkeletonWidgetOnTourItems
