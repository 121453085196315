import React, { Component } from 'react'
import GridGallery from '../GridGallery'
import LightBox from '../LightBox'
import Image from '../Image'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ImageFormat } from '../../modules/parse'
import isStolApp from '../../modules/is-stolapp'
import isServerSide from '../../modules/is-server-side'

const Wrapper = styled.div``
const StyledGalleryPhoto = styled(Image)`
  cursor: pointer;
  margin: ${(props) => props.margin + 'px'};
  z-index: 1;
`

class Gallery extends Component {
  constructor(props) {
    super(props)
    this.state = { currentImage: 0, lightboxIsOpen: false }
    this.closeLightbox = this.closeLightbox.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
  }

  openLightbox(event, index) {
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    })
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    })
  }

  render() {
    const { uid, type, direction, columns, images, photoMargin, rowHeight, ...props } = this.props
    const GalleryItem = ({ photo, onClick, index, margin, ...props }) => (
      <StyledGalleryPhoto
        margin={photoMargin}
        height={rowHeight}
        {...photo}
        onClick={(event) => {
          if (isStolApp()) {
            if (!isServerSide()) {
              try {
                window.location.href = `image-gallery://${type}/${uid}/${index}`
              } catch (error) {}
            }
            event.stopPropagation()
          } else {
            this.openLightbox(event, index)
          }
        }}
        {...props}
      />
    )

    const imagesLightbox = []
    const imagesGallery = []
    images.map((image) => {
      const photo = new ImageFormat(image.url)
      imagesLightbox.push({
        src: photo.format('max_1280x850'),
        w: 850 * image.ratio,
        h: 850,
      })
      imagesGallery.push({
        src: photo.format('max_490x225'),
        width: 225 * image.ratio,
        height: 225,
      })
      return null
    })
    return (
      <Wrapper {...props}>
        <GridGallery
          photos={imagesGallery}
          margin={photoMargin}
          direction={direction}
          targetRowHeight={rowHeight}
          columns={columns}
          renderImage={GalleryItem}
        />
        <LightBox
          images={imagesLightbox}
          galleryId={uid}
          onClose={this.closeLightbox}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
        />
      </Wrapper>
    )
  }
}

Gallery.propTypes = {
  uid: PropTypes.string,
}

Gallery.defaultProps = {
  photoMargin: 2,
  rowHeight: 140,
}

export default Gallery
