import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LocationTitle from '../../components/LocationTitle'
import {
  StyledContainer0NakedItemOnTour,
  Wrapper0NakedItemOnTour,
  Caption0NakedItemOnTour,
  Label0NakedItemOnTour,
  OnTourTitle0NakedItemOnTour,
} from '.'
import TextSkeleton, { Line0TextSkeleton } from '../../../../../../components/Text/views/TextSkeleton'
import skeletonFade from '../../../../../../modules/mixins/skeleton-fade'

const ImageSkeleton = styled.div`
  background-color: ${(props) => props.theme.color.skeletonScreen};
  width: 100%;
  padding-top: 56.25%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  ${skeletonFade}
`

const StyledTextSkeleton = styled(TextSkeleton)`
  width: 100%;
`

const StyledOnTourTitleTextSkeleton = styled(TextSkeleton)`
  ${Line0TextSkeleton} {
    border-width: 20px;
  }
`

class NakedItemOnTour extends Component {
  render() {
    const { ...props } = this.props
    return (
      <StyledContainer0NakedItemOnTour {...props}>
        <Wrapper0NakedItemOnTour>
          {
            //<StyledImage src={thumbnail.format('detail_521x293')} />
          }
          <ImageSkeleton />
          <Caption0NakedItemOnTour>
            <Label0NakedItemOnTour>
              {
                //<LocationTitle0NakedItemOnTour headline={headline} />
              }
              {
                //
              }
              <StyledTextSkeleton lastWidth="30%" />
            </Label0NakedItemOnTour>
            <OnTourTitle0NakedItemOnTour as={StyledOnTourTitleTextSkeleton} title="" />
          </Caption0NakedItemOnTour>
        </Wrapper0NakedItemOnTour>
      </StyledContainer0NakedItemOnTour>
    )
  }
}

NakedItemOnTour.propTypes = {
  headline: LocationTitle.propTypes.headline,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default NakedItemOnTour
