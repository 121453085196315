import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import HeaderOnTour from '../HeaderOnTour'
import Block from '../../../../layouts/Blocks'
import LoadMore from '../../../../components/LoadMore'
import Title from '../../../../components/Title'
import ContainerAd from '../../../../components/ContainerAd'

const StyledContainerAd = styled(ContainerAd)`
  > div > div {
    margin-bottom: ${(props) => props.theme.sizes.gapVerticalHalf};
    margin-top: ${(props) => props.theme.sizes.gapVerticalHalf};
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    > div > div {
      margin-bottom: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
      margin-top: ${(props) => props.theme.tablet.sizes.gapVerticalHalf};
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    > div > div {
      margin-bottom: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
      margin-top: ${(props) => props.theme.mobile.sizes.gapVerticalHalf};
    }
  }
`

const StyledTitle = styled(Title)`
  font-size: ${(props) => props.theme.fontSize.titleBigger};
  padding-top: ${(props) => props.theme.sizes.gapVertical};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.titleBigger};
    padding-top: ${(props) => props.theme.tablet.sizes.gapVertical};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.titleBigger};
    padding-top: ${(props) => props.theme.mobile.sizes.gapVertical};
  }
  color: ${(props) => props.theme.color.textPrimary};
  width: 100%;
  margin: 0 0 0.5em;
  @media (max-width: 450px) {
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
`

const OverviewOnTour = ({ data, ...props }) => {
  const [helperArticles, setArticles] = useState(data.articles)
  return (
    <Fragment {...props}>
      <HeaderOnTour view="bookOnTour" />
      <Block
        margin={'0px 0px 0px 0px'}
        view="ontour_items"
        data={{ onTourItems: helperArticles.slice(0, 6) }}
        numberItems={6}
      />
      <StyledContainerAd adIds={['S3', 'S4']} />
      <Block
        margin={props.theme.sizes.gapVertical + ' 0px 0px 0px'}
        view="events_and_ontour_items"
        data={{ onTourItems: helperArticles.slice(6, 9), events: data.events }}
      />
      <Block
        margin={props.theme.sizes.gapVertical + ' 0px 0px 0px'}
        view="ontour_items"
        data={{ onTourItems: helperArticles.slice(8, 14) }}
        numberItems={6}
      />
      <Block
        margin={props.theme.sizes.gapVertical + ' 0px 0px 0px'}
        view="three_article"
        data={{ articles: data.pr }}
        pr
      />
      <Block
        margin={props.theme.sizes.gapVertical + ' 0px 0px 0px'}
        view="ontour_items"
        data={{ onTourItems: helperArticles.slice(14) }}
      />
      <LoadMore
        articles={helperArticles}
        afterLoadMore={(newArticles) => {
          setArticles(newArticles)
        }}
      />
    </Fragment>
  )
}

OverviewOnTour.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    url: PropTypes.string,
    locale: PropTypes.bool,
    articles: PropTypes.array,
  }),
}

OverviewOnTour.defaultProps = {}

export default withTheme(OverviewOnTour)
