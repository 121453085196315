import React from 'react'
import { ItemOnTourWrapper0BlockEventsAndOnTourItems, Wrapper0BlockEventsAndOnTourItems } from './index'
import styled from 'styled-components'
import SkeletonWidgetEvents from '../../../../topics/Article/widgets_line/WidgetEvents/skeleton'
import SkeletonNakedItemOnTour from '../../../../topics/OnTour/components/ItemOnTour/views/NakedItemOnTour/skeleton'

const StyledSkeletonWidgetEvents = styled(SkeletonWidgetEvents)`
  flex-basis: ${(props) => props.theme.sizes.col8.slice(0, -1) + ' + ' + props.theme.sizes.gapEdge + ')'};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex-basis: ${(props) =>
      props.theme.tablet.sizes.col8.slice(0, -1) + ' + ' + props.theme.tablet.sizes.gapEdge + ')'};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-basis: ${(props) =>
      props.theme.mobile.sizes.col8.slice(0, -1) + ' + ' + props.theme.mobile.sizes.gapEdge + ')'};
  }
`

const StyledItemOnTourWrapper0BlockEventsAndOnTourItems = styled(ItemOnTourWrapper0BlockEventsAndOnTourItems)`
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.tablet.sizes.gapEdge};
    padding-left: ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    padding-right: ${(props) => props.theme.mobile.sizes.gapEdge};
    padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
  }
  @media (max-width: 450px) {
    padding-right: 0;
    padding-left: 0;
  }
`

const StyledSkeletonNakedItemOnTour = styled(SkeletonNakedItemOnTour)`
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    flex: 1;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex: 1;
  }
`

const SkeletonBlockEventsAndOnTourItems = ({ ...props }) => (
  <Wrapper0BlockEventsAndOnTourItems {...props}>
    <StyledSkeletonWidgetEvents />
    <StyledItemOnTourWrapper0BlockEventsAndOnTourItems>
      <StyledSkeletonNakedItemOnTour />
      <StyledSkeletonNakedItemOnTour />
    </StyledItemOnTourWrapper0BlockEventsAndOnTourItems>
  </Wrapper0BlockEventsAndOnTourItems>
)

export default SkeletonBlockEventsAndOnTourItems
