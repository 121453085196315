import React from 'react'
import styled from 'styled-components'
import { LinkHeadLine0TopLine } from '../../../../../../components/TopLine'
import Date from '../../../../../../components/Date'
import Text from '../../../../../../components/Text'
import styledContainerQuery from 'styled-container-query'
import TextSkeleton, { Line0TextSkeleton } from '../../../../../../components/Text/views/TextSkeleton'

const DescriptionWrapper = styled.div`
  width: ${(props) => props.theme.sizes.col8};
  padding: ${(props) => props.theme.sizes.gapVertical} ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => props.theme.tablet.sizes.col8};
    padding: ${(props) => props.theme.tablet.sizes.gapVertical} ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => props.theme.mobile.sizes.col8};
    padding: ${(props) => props.theme.mobile.sizes.gapVertical} ${(props) => props.theme.mobile.sizes.gapEdge};
  }
`

const StyledStartTime = styled(Date)`
  margin-right: 2px;
`

const DetailsWrapper = styledContainerQuery.div`
    background-color: ${(props) => props.theme.color.background};
    width: ${(props) => props.theme.sizes.col3};
    padding-top: ${(props) => props.theme.sizes.gapVertical};
    padding-bottom: ${(props) => props.theme.sizes.gapVertical};
    padding-right: calc(${(props) => props.theme.sizes.gapEdge} + 10px);
    padding-left: calc(${(props) => props.theme.sizes.gapEdge} - 10px);
    box-shadow: 5px 0 5px -2px rgba(0, 0, 0, 0.16) inset;
    &:container(min-height: 170px){
        ${StyledStartTime}{
            font-size: ${(props) => props.theme.fontSize.title3};
        }
    }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${(props) => props.theme.color.textTertiary};
  ${LinkHeadLine0TopLine} {
    font-size: ${(props) => props.theme.fontSize.title3};
    font-weight: ${(props) => props.theme.fontWeight.bold};
    color: ${(props) => props.theme.color.stol};
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    ${LinkHeadLine0TopLine} {
      font-size: ${(props) => props.theme.tablet.fontSize.title3};
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    flex-direction: column;
    ${LinkHeadLine0TopLine} {
      font-size: ${(props) => props.theme.mobile.fontSize.title3};
    }
    ${DetailsWrapper} {
      width: 100%;
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.16) inset;
      padding: ${(props) => props.theme.mobile.sizes.gapVertical} ${(props) => props.theme.mobile.sizes.gapEdge}
        ${(props) => props.theme.mobile.sizes.gapVertical} ${(props) => props.theme.mobile.sizes.gapEdge};
    }
    ${DescriptionWrapper} {
      width: calc(100% - (2 * ${(props) => props.theme.mobile.sizes.gapEdge}));
      padding-top: ${(props) => props.theme.mobile.sizes.gapVertical};
      padding-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
      padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    }
  }
`

const PlaceSkeleton = styled(TextSkeleton)`
  margin-top: 1em;
`

const TitleSkeleton = styled(TextSkeleton)`
  margin-top: 0;
  flex: 1;
  ${Line0TextSkeleton} {
    border-width: 30px;
  }
`

const HeadlineSkeleton = styled(TextSkeleton)`
  margin-top: 0;
  flex: 1;
  ${Line0TextSkeleton} {
    border-width: 20px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`
const TitlePrefix = styled(Text)`
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => props.theme.color.stol};
  margin-right: 5px;
`

const StyledText = styled(TextSkeleton)`
  margin-top: 0.5em;
`

const StartDateSkeleton = styled(TextSkeleton)`
  ${Line0TextSkeleton} {
    border-width: 20px;
  }
`

const SkeletonDescriptionDetailOnTour = ({ title, content, date, district, headline, ...props }) => (
  <Wrapper {...props}>
    <DescriptionWrapper>
      <TitleWrapper>
        {/* eslint-disable-next-line */}
        <TitlePrefix>//</TitlePrefix>
        <HeadlineSkeleton lastWidth="30%" />
      </TitleWrapper>
      <TitleSkeleton lastWidth="80%" />
      <StyledText lines={3} lastWidth="50%" />
    </DescriptionWrapper>
    <DetailsWrapper>
      <StartDateSkeleton lastWidth="70%" />
      <PlaceSkeleton lastWidth="50%" />
    </DetailsWrapper>
  </Wrapper>
)

export default SkeletonDescriptionDetailOnTour
