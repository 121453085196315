import React, { Fragment } from 'react'
import { withTheme } from 'styled-components'
import HeaderOnTour from '../HeaderOnTour'
import SkeletonWidgetOnTourItems from '../../widgets/WidgetOnTourItems/skeleton'
import SkeletonBlockEventsAndOnTourItems from '../../../../layouts/Blocks/views_ontour/BlockEventsAndOnTourItems/skeleton'
import SkeletonBlockThreeArticle from '../../../../layouts/Blocks/views_article/BlockThreeArticle/skeleton'

const SkeletonOverviewOnTour = ({ ...props }) => (
  <Fragment {...props}>
    <HeaderOnTour view="bookOnTour" />
    <SkeletonWidgetOnTourItems margin={props.theme.sizes.gapVertical + ' 0px 0px 0px'} numberItems={6} />
    <SkeletonBlockEventsAndOnTourItems />
    <SkeletonWidgetOnTourItems margin={props.theme.sizes.gapVertical + ' 0px 0px 0px'} numberItems={6} />
    <SkeletonBlockThreeArticle
      margin={props.theme.sizes.gapVertical + ' ' + props.theme.sizes.gapEdge + ' 0px ' + props.theme.sizes.gapEdge}
    />
    <SkeletonWidgetOnTourItems margin={props.theme.sizes.gapVertical + ' 0px 0px 0px'} numberItems={6} />
    <SkeletonWidgetOnTourItems margin={props.theme.sizes.gapVertical + ' 0px 0px 0px'} numberItems={6} />
  </Fragment>
)

export default withTheme(SkeletonOverviewOnTour)
