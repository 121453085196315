import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import HeaderOnTour from '../HeaderOnTour'
import isStolApp from '../../../../modules/is-stolapp'
import styled, { withTheme } from 'styled-components'
import CircularProgress from '../../../../components/CircularProgress'
import SkeletonDescriptionDetailOnTour from './components/DescriptionDetailOnTour/skeleton'
import DescriptionDetailOnTour from './components/DescriptionDetailOnTour'

const StyledCircularProgress = styled(CircularProgress)`
  text-align: center;
  padding-top: 15%;
`

const SkeletonDetailOnTour = ({ fontSize, routeState, ...props }) => (
  <Fragment {...props}>
    <HeaderOnTour view="viewGalleryOnTour" />
    {routeState && routeState.title && routeState.date && routeState.breadcrumb && routeState.breadcrumb.headline && (
      <DescriptionDetailOnTour
        title={routeState.title}
        content={routeState.content}
        date={routeState.date}
        headline={routeState.breadcrumb && routeState.breadcrumb.headline}
        location={routeState.location}
        eventLocation={routeState.locationEvent}
        fontSize={fontSize}
      />
    )}
    {!routeState && (isStolApp() ? <StyledCircularProgress /> : <SkeletonDescriptionDetailOnTour />)}
  </Fragment>
)

SkeletonDetailOnTour.propTypes = {
  routeState: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    date: PropTypes.string,
    breadcrumb: PropTypes.shape({
      headline: PropTypes.object,
    }),
    location: PropTypes.string,
    locationEvent: PropTypes.string,
  }),
  fontSize: PropTypes.number,
}

export default withTheme(SkeletonDetailOnTour)
