import React from 'react'
import PropTypes from 'prop-types'
import ScreenOnTourOverview from './screens/ScreenOnTourOverview'
import ScreenOnTourDetail from './screens/ScreenOnTourDetail'

const onTourComponents = {
  ontour: ScreenOnTourOverview,
  'ontour.detail': ScreenOnTourDetail,
}

const ScreenOnTour = ({ view, ...props }) => {
  const OnTourComponent = onTourComponents[view]
  return <OnTourComponent {...props} />
}

ScreenOnTour.propTypes = {
  view: PropTypes.string,
}

export default ScreenOnTour
