import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Title from '../../../../../../components/Title'
import TitleDescriptionPage from '../../../../../../components/TitleDescriptionPage'
import TopLine, { LinkHeadLine0TopLine } from '../../../../../../components/TopLine'
import Date from '../../../../../../components/Date'
import Text from '../../../../../../components/Text'
import translate from '../../../../../../modules/translate'
import { htmlParser } from '../../../../../../modules/parse'
import moment from 'moment'
import { translateFontSizeToTheme } from '../../../../../../modules/font'

const DescriptionWrapper = styled.div`
  width: ${(props) => props.theme.sizes.col8};
  padding: ${(props) => props.theme.sizes.gapVertical} ${(props) => props.theme.sizes.gapEdge};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => props.theme.tablet.sizes.col8};
    padding: ${(props) => props.theme.tablet.sizes.gapVertical} ${(props) => props.theme.tablet.sizes.gapEdge};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => props.theme.mobile.sizes.col8};
    padding: ${(props) => props.theme.mobile.sizes.gapVertical} ${(props) => props.theme.mobile.sizes.gapEdge};
  }
`

const StyledText = styled(Text)`
  display: inline-flex;
  width: 100%;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title3};
  }
`

const StyledStartTime = styled(Date)`
  margin-right: 2px;
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.title3};
  }
`

const DetailsWrapper = styled.div`
  background-color: ${(props) => props.theme.color.background};
  width: ${(props) => props.theme.sizes.col3};
  padding-top: ${(props) => props.theme.sizes.gapVertical};
  padding-bottom: ${(props) => props.theme.sizes.gapVertical};
  padding-right: calc(${(props) => props.theme.sizes.gapEdge} + 10px);
  padding-left: calc(${(props) => props.theme.sizes.gapEdge} - 10px);
  box-shadow: 5px 0 5px -2px rgba(0, 0, 0, 0.16) inset;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${(props) => props.theme.color.textTertiary};
  ${LinkHeadLine0TopLine} {
    font-size: ${(props) => props.theme.fontSize.title3};
    font-weight: ${(props) => props.theme.fontWeight.bold};
    color: ${(props) => props.theme.color.stol};
  }
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    ${LinkHeadLine0TopLine} {
      font-size: ${(props) => props.theme.tablet.fontSize.title3};
    }
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    ${LinkHeadLine0TopLine} {
      font-size: ${(props) => props.theme.mobile.fontSize.title3};
    }
    flex-direction: column;
    ${DetailsWrapper} {
      width: calc(100% - 40px);
      box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.16) inset;
      padding: ${(props) => props.theme.mobile.sizes.gapVertical} ${(props) => props.theme.mobile.sizes.gapEdge}
        ${(props) => props.theme.mobile.sizes.gapVertical} ${(props) => props.theme.mobile.sizes.gapEdge};
    }
    ${DescriptionWrapper} {
      width: calc(100% - (2 * ${(props) => props.theme.mobile.sizes.gapEdge}));
      padding-top: ${(props) => props.theme.mobile.sizes.gapVertical};
      padding-bottom: ${(props) => props.theme.mobile.sizes.gapVertical};
      padding-left: ${(props) => props.theme.mobile.sizes.gapEdge};
    }
  }
`

const StyledStartDate = styled(Date)`
  font-size: ${(props) => props.theme.fontSize.text};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.tablet.fontSize.text};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => props.theme.mobile.fontSize.text};
  }
  color: ${(props) => props.theme.color.stolDark};
  font-family: ${(props) => props.theme.font.secondary};
  padding-bottom: 20px;
  width: 100%;
`

const StyledTitle = styled(Title)`
  margin-top: 0;
`

const SingleDetailWrapper = styled.div``

const StyledTitleDescriptionPage = styled(TitleDescriptionPage)`
  font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.default.fontSize.base)};
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.tablet.fontSize.base)};
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    font-size: ${(props) => translateFontSizeToTheme(props.fontSize, props.theme.mobile.fontSize.base)};
  }
`

const DescriptionDetailOnTour = ({ title, content, date, location, eventLocation, headline, fontSize, ...props }) => {
  let timeFormat = moment(date).format('HH:mm') === '00:00' ? 'dddd, D. MMMM YYYY' : 'dddd, D. MMMM YYYY, HH:mm'
  return (
    <Wrapper {...props}>
      <DescriptionWrapper>
        <TopLine headline={headline} headlinePrefix="//" />
        <StyledTitle>{title}</StyledTitle>
        <StyledTitleDescriptionPage fontSize={fontSize}>{htmlParser(content)}</StyledTitleDescriptionPage>
      </DescriptionWrapper>
      <DetailsWrapper>
        <StyledStartDate format={timeFormat}>{date}</StyledStartDate>
        <SingleDetailWrapper>
          {location && <StyledText>{translate('ontour_event_detail_place') + ': ' + location}</StyledText>}
          {eventLocation && (
            <StyledText>{translate('ontour_event_detail_event_place') + ': ' + eventLocation}</StyledText>
          )}
        </SingleDetailWrapper>
      </DetailsWrapper>
    </Wrapper>
  )
}

DescriptionDetailOnTour.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  date: PropTypes.string,
  location: PropTypes.string,
  eventLocation: PropTypes.string,
  headline: PropTypes.string,
  fontSize: PropTypes.number,
}

DescriptionDetailOnTour.defaultProps = {
  content: '',
  location: '',
  eventLocation: null,
}

export default DescriptionDetailOnTour
