import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BookOnTourHeader from './views/BookOnTourHeader'
import WidgetOnTourHeader from './views/WidgetOnTourHeader'
import ViewGalleryOnTourHeader from './views/ViewGalleryOnTourHeader'

const components = {
  bookOnTour: BookOnTourHeader,
  widgetOnTour: WidgetOnTourHeader,
  viewGalleryOnTour: ViewGalleryOnTourHeader
}

class HeaderOnTour extends Component {
  render() {
    const { view, ...props } = this.props
    const TagName = components[view]
    return <TagName {...props} />
  }
}

HeaderOnTour.propTypes = {
  view: PropTypes.oneOf(Object.keys(components)).isRequired,
}

HeaderOnTour.defaultProps = {
  view: 'widgetOnTour',
}

export default HeaderOnTour
